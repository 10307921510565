import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaSpinner } from "react-icons/fa6";
import { putResetPassword, postResetPassword } from "../../api/authService";

function ResetPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const reset_key = searchParams.get("reset_key");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [nickname, setNickname] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const isPasswordValid = password.length >= 6;
  const isPasswordMatch = password === confirmPassword;
  const isNicknameValid = nickname.length >= 6;
  const isFormValid = isPasswordValid && isPasswordMatch && reset_key;
  const isRequestValid = isNicknameValid && !reset_key;

  const handleReset = async () => {
    if (!isFormValid) return;
    setLoading(true);
    try {
      await putResetPassword(reset_key, password, confirmPassword);
      setMessage(t("password_changed"));
      setIsSuccess(true);
    } catch (err) {
      setError(err.error?.message || t("reset_password_error"));
    } finally {
      setLoading(false);
    }
  };

  const handleRequestReset = async () => {
    if (!isRequestValid) return;
    setLoading(true);
    try {
      const response = await postResetPassword(nickname);
      if (response.status === 200) {
        setMessage(t("password_reset_email_sent"));
        setIsSuccess(true);
      }
    } catch (err) {
      setError(err.error?.message || t("reset_request_error"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center text-white text-center -mt-12">
      {isSuccess ? (
        <>
          <p className="text-green-500 text-lg font-bold">{message}</p>
          {reset_key && (
            <button
              type="button"
              onClick={() => navigate("/auth")}
              className="mt-4 px-4 py-2 rounded-md bg-orange-500 hover:bg-orange-600 text-white"
            >
              {t("go_to_login")}
            </button>
          )}
        </>
      ) : reset_key ? (
        <>
          <h2 className="text-2xl font-bold text-center mb-4">
            {t("set_new_password")}
          </h2>
          {error && <p className="text-red-500">{error}</p>}
          <div>
            <label className="block text-neutral-400 mb-1">
              {t("password")}
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t("password_placeholder")}
              className={`w-full p-2 rounded bg-neutral-700 border ${
                isPasswordValid || !password
                  ? "border-neutral-600"
                  : "border-red-500"
              } focus:outline-none focus:border-orange-500`}
            />
            {!isPasswordValid && password.length > 0 && (
              <p className="text-red-500 text-sm mt-1">
                {t("password_too_short")}
              </p>
            )}
          </div>
          <div>
            <label className="block text-neutral-400 mb-1">
              {t("confirm_password")}
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder={t("confirm_password_placeholder")}
              className={`w-full p-2 rounded bg-neutral-700 border ${
                isPasswordMatch || !confirmPassword
                  ? "border-neutral-600"
                  : "border-red-500"
              } focus:outline-none focus:border-orange-500`}
            />
          </div>
          <button
            type="button"
            onClick={handleReset}
            disabled={!isFormValid || loading}
            className={`mt-4 px-4 py-2 rounded-md flex items-center justify-center gap-2 ${
              isFormValid && !loading
                ? "bg-orange-500 hover:bg-orange-600 text-white"
                : "bg-neutral-500 text-neutral-300 cursor-not-allowed"
            }`}
          >
            {loading ? <FaSpinner className="animate-spin" /> : t("change_password")}
          </button>
        </>
      ) : (
        <>
          <h2 className="text-2xl font-bold text-center mb-4">
            {t("request_password_reset")}
          </h2>
          {error && <p className="text-red-500">{error}</p>}
          <div>
            <input
              type="text"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              placeholder={t("auth_login_placeholder")}
              className={`w-full p-2 rounded bg-neutral-700 border ${
                isNicknameValid || !nickname
                  ? "border-neutral-600"
                  : "border-red-500"
              } focus:outline-none focus:border-orange-500`}
            />
          </div>
          <button
            type="button"
            onClick={handleRequestReset}
            disabled={!isRequestValid || loading}
            className={`mt-4 px-4 py-2 rounded-md flex items-center justify-center gap-2 ${
              isRequestValid && !loading
                ? "bg-orange-500 hover:bg-orange-600 text-white"
                : "bg-neutral-500 text-neutral-300 cursor-not-allowed"
            }`}
          >
            {loading ? <FaSpinner className="animate-spin" /> : t("send_reset_request")}
          </button>
        </>
      )}
    </div>
  );
}

export default ResetPassword;
