import axios from "axios";

const API = "https://api.magatamy.com";

export const getHotInfoBar = async () => {
  try {
    const response = await axios.get(`${API}/hotInfoBar`);

    return response.data;
  } catch (error) {
    console.error("getHotInfoBar error:", error);
    throw new Error(error.response?.data?.message || "getHotInfoBar failed");
  }
};

export const getProducts = async (category) => {
  try {
    const response = await axios.get(`${API}/products`, {
      params: { category: category },
    });

    return response.data;
  } catch (error) {
    console.error("getProducts error:", error);
    throw new Error(error.response?.data?.message || "getProducts failed");
  }
};

export const getPurchasedProducts = async () => {
  try {
    const response = await axios.get(`${API}/products/purchased`);

    return response.data;
  } catch (error) {
    console.error("getPurchasedProducts error:", error);
    throw new Error(
      error.response?.data?.message || "getPurchasedProducts failed"
    );
  }
};

export const getAdminGuilds = async () => {
  try {
    const token = localStorage.getItem("jwtToken");
    const response = await axios.get(`${API}/discord/adminGuilds`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("getAdminGuilds error:", error);
    if (error.response?.status === 403) {
      throw new Error("FORBIDDEN");
    }
    throw new Error(error.response?.data?.message || "getAdminGuilds failed");
  }
};

export const checkPromoCode = async (promoCode) => {
  try {
    const token = localStorage.getItem("jwtToken");
    const response = await axios.get(`${API}/payment/promoCode`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: { promo_code: promoCode },
    });
    return response.data;
  } catch (error) {
    console.error("checkPromoCode error:", error);

    // Перевіряємо, чи є в помилці інформація про тип
    const errorType = error.response?.data?.error?.type;
    if (error.response?.status === 403 && errorType === "DISCORD_TOKEN_EXPIRED") {
      throw new Error("DISCORD_TOKEN_EXPIRED");
    }

    throw new Error(error.response?.data?.message || "checkPromoCode failed");
  }
};

export const pay = async (data) => {
  try {
    console.log(data);
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      throw new Error("Token is missing");
    }

    const response = await axios.post(`${API}/payment/generateLick`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    return response.data; // очікуємо, що тут буде посилання для редіректу
  } catch (error) {
    console.error("pay error:", error.response?.data?.error?.message || error.message);
    throw new Error(error.response?.data?.error?.message || "pay failed");
  }
};




