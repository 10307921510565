import axios from "axios";

const API = "https://api.magatamy.com";

export const login = async (nickname, password) => {
  try {
    const response = await axios.post(`${API}/authorize/login`, {
      nickname: nickname.toLowerCase(),
      password: password,
    });

    const { access_token } = response.data;

    // Збереження токена в localStorage
    localStorage.setItem("jwtToken", access_token);

    return access_token;
  } catch (error) {
    console.error("Login error:", error);
    throw new Error(error.response?.data?.message || "Login failed");
  }
};

export const emailCode = async (email) => {
  try {
    const response = await axios.post(`${API}/authorize/emailCode`, {
      email: email,
    });

    const { status } = response.status;

    return status;
  } catch (error) {
    console.error("Email code error:", error);
    throw new Error(error.response?.data?.message || "Email code failed");
  }
};

export const registration = async (
  displayName,
  nickname,
  email,
  code,
  password,
  password_confirm
) => {
  try {
    const response = await axios.post(`${API}/authorize/registration`, {
      display_name: displayName,
      nickname: nickname.toLowerCase(),
      email: email,
      password: password,
      password_confirm: password_confirm,
      code: parseInt(code),
    });

    if (response.data?.error) {
      throw new Error(response.data.error);
    }

    return response.data;
  } catch (error) {
    console.error("Registration error:", error);
    return error;
    // throw new Error(error.response?.data?.error || "Registration failed");
  }
};

export const getUserData = async (clearCart) => {
  try {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      if (typeof clearCart === "function") {
        clearCart();
      }
      throw new Error("Token is missing");
    }

    const response = await axios.get(`${API}/user/me`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    localStorage.removeItem("jwtToken");
    if (typeof clearCart === "function") {
      clearCart();
    }
    // console.error("getUserData error:", error);
    return null;
  }
};


export const putResetPassword = async (reset_key, password, confirmPassword) => {
  try {
    const response = await axios.put(`${API}/authorize/resetPassword`, {
      reset_key: reset_key,
      password: password,
      password_confirm: confirmPassword
    });
    console.log(response);
    
    return response;
  } catch (error) {
    console.error("putResetPassword error:", error.response?.data || error);
    throw error.response?.data || new Error("Unknown error occurred");
  }
};

export const postResetPassword = async (nickname) => {
  try {
    const response = await axios.post(`${API}/authorize/resetPassword`, {
      nickname: nickname.toLowerCase(),
    });
    console.log(response);
    return response;
  } catch (error) {
    console.error("postResetPassword error:", error.response?.data || error);
    throw error.response?.data || new Error("Unknown error occurred");
  }
};

export const getUserSetting = async () => {
  try {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      throw new Error("Token is missing");
    }

    const response = await axios.get(`${API}/user/settings`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    console.error("getUserSetting error:", error.response?.data || error);
    throw error.response?.data || new Error("Unknown error occurred");
  }
};

export const editUserSetting = async (data) => {
  try {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      throw new Error("Token is missing");
    }

    const response = await axios.put(`${API}/user/settings`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error) {
    console.error("editUserSetting error:", error.response?.data || error);

    // Отримуємо повідомлення про помилку
    const errorMessage = error.response?.data?.message || "Unknown error occurred";
    throw new Error(errorMessage);
  }
};

