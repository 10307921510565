import React, { useState, useEffect, useRef } from "react";
import {
  FaDiscord,
  FaGoogle,
  FaLock,
  FaPen,
  FaXmark,
  FaLinkSlash,
} from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { editUserSetting, postResetPassword, getUserSetting } from "../../api/authService";
import { useSnackbar } from "../../context/SnackbarContext";

function UserProfile({ userData, isLoading }) {
  const { t } = useTranslation();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Стан редагування профілю
  const [isEditing, setIsEditing] = useState(false);
  const [editedDisplayName, setEditedDisplayName] = useState("");
  const [editedEmail, setEditedEmail] = useState("");
  const [editedNickname, setEditedNickname] = useState("");

  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // Стан для вибору аватара
  const [availableAvatars, setAvailableAvatars] = useState([]);
  const [selectedAvatar, setSelectedAvatar] = useState("");
  const [showAvatarMenu, setShowAvatarMenu] = useState(false);
  const avatarMenuRef = useRef(null);
  const [activeButtons, setActiveButtons] = useState({
    google: false,
    discord: false,
  });
  const [timeoutChangeNickname, setTimeoutChangeNickname] = useState(null);
  // Перевірка автентифікації та завантаження даних
  useEffect(() => {
    if (
      !localStorage.getItem("jwtToken") ||
      (!isLoading && (!userData || userData === null))
    ) {
      navigate("/auth");
    }
  }, [userData, navigate, isLoading]);

  // Ініціалізація локальних станів для профілю при отриманні userData
  useEffect(() => {
    if (userData) {
      setEditedDisplayName(userData.display_name);
      setEditedEmail(userData.email);
      setEditedNickname(userData.nickname);
    }
  }, [userData]);

  // Виклик функції getUserSetting при завантаженні компонента
  useEffect(() => {
    async function fetchSettings() {
      try {
        const settings = await getUserSetting();
        setTimeoutChangeNickname(settings.timeout_change_nickname);
        setAvailableAvatars(settings.available_avatars || []);
        setSelectedAvatar(settings.avatar_url);
      } catch (error) {
        console.error("Error fetching user settings:", error);
      }
    }
    fetchSettings();
  }, []);

  // Закриття меню аватарів при кліку поза його межами
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        avatarMenuRef.current &&
        !avatarMenuRef.current.contains(event.target)
      ) {
        setShowAvatarMenu(false);
      }
    }
    if (showAvatarMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showAvatarMenu]);

  const logout = () => {
    localStorage.removeItem("jwtToken");
    navigate("/");
  };

  const link = (service) => {
    return `https://api.magatamy.com/authorize/login${service}?jwt=${localStorage.getItem(
      "jwtToken"
    )}`;
  };

  // Визначення, чи були внесені зміни в профіль
  const hasChanges =
    userData &&
    (editedDisplayName !== userData.display_name ||
      editedEmail !== userData.email ||
      editedNickname !== userData.nickname ||
      selectedAvatar !== userData.avatar_url ||
      activeButtons.discord ||
      activeButtons.google);

  const validate = () => {
    let newErrors = {};

    if (editedDisplayName.length < 6) {
      newErrors.displayName = t("validation.displayName");
    }

    if (editedNickname.length < 6) {
      newErrors.nickname = t("validation.nickname");
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(editedEmail)) {
      newErrors.email = t("validation.email");
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validate()) return;
  
    const data = {
      ...(editedDisplayName &&
        editedDisplayName !== userData.display_name && {
          display_name: editedDisplayName,
        }),
      ...(editedEmail && editedEmail !== userData.email && { email: editedEmail }),
      ...(editedNickname &&
        editedNickname.toLowerCase() !== userData.nickname && {
          nickname: editedNickname.toLowerCase(),
        }),
      ...(selectedAvatar !== userData.avatar_url && { avatar_url: selectedAvatar }),
      ...(activeButtons.google && { google_unlink: activeButtons.google }),
      ...(activeButtons.discord && { discord_unlink: activeButtons.discord }),
    };
  
    try {
      const response = await editUserSetting(data);
      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      const errorMessage = error.message || "Не вдалося оновити профіль";
      showSnackbar(errorMessage, "error");
      console.error("Error updating profile:", error);
    }
  };  

  const handleCancel = () => {
    if (userData) {
      setEditedDisplayName(userData.display_name);
      setEditedEmail(userData.email);
      setEditedNickname(userData.nickname);
      setSelectedAvatar(userData.avatar_url);
    }
    setIsEditing(false);
  };

  const handleRequestReset = async (nickname) => {
    setLoading(true);
    try {
      const response = await postResetPassword(nickname);
      if (response.status === 200) {
        showSnackbar("password_reset_email_sent", "success");
        setIsSuccess(true);
      }
    } catch (err) {
      showSnackbar(err.error?.message || t("reset_request_error"), "error");
      console.log(err.error?.message || t("reset_request_error"));
      if (err.error?.type === "TIMEOUT_ON_METHOD") {
        setIsSuccess(true);
      }
    } finally {
      setLoading(false);
    }
  };

  const toggleButton = (key) => {
    setActiveButtons((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  function formatTimestamp(timestamp) {
    const futureDate = new Date(Date.now() + timestamp * 1000); // Додаємо секунди до поточного часу
    const hours = String(futureDate.getHours()).padStart(2, "0");
    const minutes = String(futureDate.getMinutes()).padStart(2, "0");
    const day = String(futureDate.getDate()).padStart(2, "0");
    const month = String(futureDate.getMonth() + 1).padStart(2, "0"); // Місяці починаються з 0
    const year = futureDate.getFullYear();
  
    return `${hours}:${minutes} ${day}.${month}.${year}`;
  }
  

  return (
    <div className="">
      {/* Верхня частина профілю */}
      <div className="-mx-4 flex flex-col sm:flex-row items-center gap-6 bg-gradient-to-t from-orange-900 to-orange-900/10 p-6">
        {userData ? (
          <div className="relative inline-block sm:w-32">
            <img
              src={isEditing ? selectedAvatar : userData.avatar_url}
              alt={t("avatar_alt")}
              className="w-28 h-28 rounded-full border-4 border-neutral-700"
            />
            {/* Кнопка для відкриття/закриття меню вибору аватара */}
            {isEditing ? (
              <button
                onClick={() => setShowAvatarMenu((prev) => !prev)}
                className="absolute top-0 -right-2 bg-white rounded-full p-1"
              >
                {showAvatarMenu ? (
                  <FaXmark className="text-black" />
                ) : (
                  <FaPen className="text-black" />
                )}
              </button>
            ) : (
              <></>
            )}
            {/* Меню вибору аватара відображається без фонового оверлею */}
            {showAvatarMenu && (
              <div
                ref={avatarMenuRef}
                className="absolute mt-2 top-full -left-16 sm:top-0 sm:left-32 sm:mt-0 bg-neutral-900 p-4 rounded shadow-lg z-40 w-64"
              >
                <div className="grid grid-cols-3 gap-2">
                  {availableAvatars.map((url, idx) => (
                    <img
                      key={idx}
                      src={url}
                      alt={`avatar-${idx}`}
                      onClick={() => setSelectedAvatar(url)}
                      className={`w-16 h-16 rounded-full cursor-pointer border-2 ${
                        selectedAvatar === url
                          ? "border-orange-500"
                          : "border-transparent"
                      }`}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        ) : (
          // Заглушка для аватару
          <div className="w-32 h-28 rounded-full bg-neutral-700 border-4 border-neutral-800 animate-pulse" />
        )}
        <div className="text-white text-start w-full flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="flex flex-col">
            {isEditing ? (
              <>
                <div className="flex flex-col lg:flex-row items-center">
                  <div className="flex flex-col">
                    <input
                      type="text"
                      value={editedDisplayName}
                      onChange={(e) => setEditedDisplayName(e.target.value)}
                      className={`text-2xl font-semibold bg-transparent text-white border-b w-64 lg:w-36 xl:w-64 ${
                        errors.displayName ? "border-red-500" : "border-white"
                      }`}
                    />
                    {errors.displayName && (
                      <p className="text-red-500 text-xs">
                        {errors.displayName}
                      </p>
                    )}
                  </div>

                  <p className="text-gray-300 xl:inline lg:text-xs xl:text-base">
                    <FaPen className="inline -mt-1 mx-2" />
                    {t("nickname")}
                  </p>
                </div>

                <div className="flex flex-col lg:flex-row items-center">
                  {timeoutChangeNickname === null ? (
                    <div className="flex flex-col">
                      <input
                        type="text"
                        value={editedNickname}
                        onChange={(e) => setEditedNickname(e.target.value)}
                        className={`text-neutral-300 bg-transparent text-white border-b w-64 lg:w-36 xl:w-64 ${
                          errors.nickname ? "border-red-500" : "border-white"
                        }`}
                      />
                      {errors.nickname && (
                        <p className="text-red-500 text-xs">
                          {errors.nickname}
                        </p>
                      )}
                    </div>
                  ) : (
                    <p className="w-64 lg:w-36 xl:w-64 bg-neutral-800 text-center rounded-md inline-block mt-1 text-sm py-1">
                      <FaLock className="inline -mt-1 mx-2" />
                      {formatTimestamp(timeoutChangeNickname)}
                    </p>
                  )}
                  <p className="text-gray-300 xl:inline lg:text-xs xl:text-base">
                    <FaPen className="inline -mt-1 mx-2" />
                    {t("login")}
                  </p>
                </div>

                <div className="flex flex-col lg:flex-row items-center">
                  <div className="flex flex-col">
                    <input
                      type="email"
                      value={editedEmail}
                      onChange={(e) => setEditedEmail(e.target.value)}
                      className={`text-neutral-300 bg-transparent text-white border-b w-64 lg:w-36 xl:w-64 ${
                        errors.email ? "border-red-500" : "border-white"
                      }`}
                    />
                    {errors.email && (
                      <p className="text-red-500 text-xs">{errors.email}</p>
                    )}
                  </div>
                  <p className="text-gray-300 xl:inline lg:text-xs xl:text-base">
                    <FaPen className="inline -mt-1 mx-2" />
                    {t("email")}
                  </p>
                </div>
              </>
            ) : userData ? (
              <>
                <h2 className="text-2xl font-semibold">
                  {userData.display_name}
                </h2>
                <p className="text-neutral-300">{userData.email}</p>
              </>
            ) : (
              <>
                <div className="h-6 w-32 bg-neutral-700 rounded animate-pulse mb-2"></div>
                <div className="h-4 w-48 bg-neutral-700 rounded animate-pulse"></div>
              </>
            )}
          </div>
          {isEditing && (
            <div className="flex flex-col gap-2 justify-between">
              <button
                disabled={!userData.discord_link}
                onClick={() => toggleButton("discord")}
                className={`flex items-center justify-center py-1 px-2 text-sm rounded-md gap-2 transition ${
                  !userData.discord_link
                    ? "bg-gray-500 cursor-not-allowed"
                    : activeButtons.discord
                    ? "bg-blue-800"
                    : "bg-blue-600 hover:bg-blue-700"
                } text-white`}
              >
                <FaDiscord className="h-5 w-5" />
                <span>{t("unlink")}</span>
                <FaLinkSlash className="h-5 w-5" />
              </button>
              <button
                disabled={!userData.google_link}
                onClick={() => toggleButton("google")}
                className={`flex items-center justify-center py-1 px-2 text-sm rounded-md gap-2 transition ${
                  !userData.google_link
                    ? "bg-gray-500 cursor-not-allowed"
                    : activeButtons.google
                    ? "bg-red-800"
                    : "bg-red-600 hover:bg-red-700"
                } text-white`}
              >
                <FaGoogle className="h-5 w-5" />
                <span>{t("unlink")}</span>
                <FaLinkSlash className="h-5 w-5" />
              </button>
            </div>
          )}
          <div className="flex md:flex-col gap-2 text-sm">
            {isEditing ? (
              <>
                <button
                  onClick={handleSave}
                  disabled={!hasChanges}
                  className="px-3 py-1 border text-white rounded-md hover:bg-orange-700 disabled:opacity-50 disabled:hover:bg-transparent"
                >
                  {t("save")}
                </button>
                <button
                  onClick={handleCancel}
                  className="px-3 py-1 border text-white rounded-md hover:bg-orange-700"
                >
                  {t("cancel")}
                </button>
              </>
            ) : (
              <div className="flex flex-col text-sm gap-2">
                <button
                  onClick={() => setIsEditing(true)}
                  className="px-3 py-1 border text-white rounded-md hover:bg-orange-700"
                >
                  {t("edit_profile")}
                </button>

                <button
                  onClick={() => handleRequestReset(userData.nickname)}
                  disabled={loading || isSuccess}
                  className="px-3 py-1 border text-white rounded-md hover:bg-orange-700 disabled:opacity-50 disabled:hover:bg-transparent"
                >
                  {loading
                    ? t("loading")
                    : isSuccess
                    ? t("password_reset_success")
                    : t("change_password")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Основна частина профілю */}
      <div className="md:grid md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-3 gap-6 my-8">
        {/* Права колонка - Поле для промокоду */}
        <div className="col-span-1 lg:col-span-2 xl:col-span-1 bg-neutral-800 py-2 lg:p-4 rounded-md">
          <h3 className="text-lg font-semibold text-white mb-4">
            {t("activate_promo_code")}
          </h3>
          <input
            type="text"
            placeholder={t("enter_promo_code")}
            className="w-full p-2 rounded-md bg-neutral-700 text-white outline-none"
          />
          <button className="mt-4 w-full bg-orange-500 text-white py-2 rounded-md hover:bg-orange-600">
            {t("activate")}
          </button>
          <hr className="my-6 border-neutral-500" />

          {userData && !userData.discord_link ? (
            <Link
              to={link("Discord")}
              className="w-full flex items-center justify-center bg-blue-600 text-white py-3 rounded-md hover:bg-blue-700 mb-4"
            >
              <FaDiscord className="h-5 w-5 mr-2" />
              {t("link_discord")}
            </Link>
          ) : (
            <div className="w-full flex items-center justify-center border-2 border-blue-600 text-blue-600 py-3 rounded-md mb-4 cursor-default">
              <FaDiscord className="h-5 w-5 mr-2" />
              {t("linked_discord")}
            </div>
          )}
          {userData && !userData.google_link ? (
            <Link
              to={link("Google")}
              className="w-full flex items-center justify-center bg-red-600 text-white py-3 rounded-md hover:bg-red-700 mb-4"
            >
              <FaGoogle className="h-5 w-5 mr-2" />
              {t("link_google")}
            </Link>
          ) : (
            <div className="w-full flex items-center justify-center border-2 border-red-600 text-red-600 py-3 rounded-md mb-4 cursor-default">
              <FaGoogle className="h-5 w-5 mr-2" />
              {t("linked_google")}
            </div>
          )}

          <button
            onClick={logout}
            className="w-full py-3 border text-white rounded-md hover:bg-orange-700"
          >
            {t("logout")}
          </button>
        </div>
        {/* Інвентар */}
        {/* ... */}
      </div>
    </div>
  );
}

export default UserProfile;
