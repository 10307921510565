import React, { useState, useEffect, useRef } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
const Carousel = () => {
  const slides = [
    { id: 1, bg: "/slider1.png" },
    { id: 2, bg: "/slider2.png" },
    { id: 3, bg: "/slider3.png" },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const slideInterval = 8000; // Час у мілісекундах для авто перемикання
  const timerRef = useRef(null);

  // Функція для перемикання на наступний слайд
  const nextSlide = () => {
    setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
    resetTimer();
  };

  // Функція для перемикання на попередній слайд
  const prevSlide = () => {
    setCurrentSlide((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
    resetTimer();
  };

  // Скидання таймера при ручному або автоматичному перемиканні
  const resetTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
    timerRef.current = setInterval(nextSlide, slideInterval);
  };

  // Запуск таймера при першому рендерингу
  useEffect(() => {
    resetTimer();
    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  return (
    <div className="relative h-72 overflow-hidden my-4 rounded-xl">
      {/* Слайди */}
      <div
        className="flex transition-transform duration-500"
        style={{ transform: `translateX(-${currentSlide * 100}%)` }}
      >
        {slides.map((slide) => (
          <div
            key={slide.id}
            className="w-full flex-shrink-0 h-72 flex justify-center items-center bg-cover bg-center"
            style={{ backgroundImage: `url(${slide.bg})` }}
          >
            {slide.conten ? (
              <h2 className="text-2xl font-bold text-white bg-black bg-opacity-50 p-4 rounded">
                {slide.content}
              </h2>
            ) : (
              <></>
            )}
          </div>
        ))}
      </div>

      {/* Кнопки перемикання */}
      <button
        onClick={prevSlide}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white p-2 h-full bg-gradient-to-r from-orange-950/50 hover:lg:from-orange-950/90 hover:lg:text-orange-500 transition-all duration-300 ease-in-out"
      >
        <ChevronLeftIcon className="h-10" />
      </button>
      <button
        onClick={nextSlide}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 text-white p-2 h-full bg-gradient-to-l from-orange-950/50 hover:lg:from-orange-950/90 hover:lg:text-orange-500 transition-all duration-300 ease-in-out"
      >
        <ChevronRightIcon className="h-10" />
      </button>
    </div>
  );
};

export default Carousel;
