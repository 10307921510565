import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaCircleCheck } from "react-icons/fa6";

function Paid() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!searchParams.toString()) {
      navigate("/", { replace: true }); // Перенаправлення, якщо немає параметрів
    }
  }, [searchParams, navigate]);

  const handleGoHome = () => {
    navigate("/");
  };

  return (
    <div className="h-full flex flex-col items-center justify-center text-white text-center">
      <FaCircleCheck className="text-9xl font-bold text-green-700" />
      <h2 className="text-2xl mt-4">{t("thanks_for_purchase")}</h2>
      <button
        onClick={handleGoHome}
        className="mt-6 px-6 py-2 border rounded-md hover:border-orange-600 hover:text-orange-600 transition-colors"
      >
        {t("page_404_return")}
      </button>
    </div>
  );
}

export default Paid;
