import { MdOutlineShoppingCart, MdShoppingCart } from "react-icons/md";
import { useCart } from "../../context/CartContext";
import { usePopup } from "../../context/PopupContext";

const ProductCard = ({ product, userData }) => {
  const lang = localStorage.getItem("i18nextLng") || "en";
  const { openPopup } = usePopup();
  const { addToCart, removeFromCart, isInCart } = useCart();
  const { id, title, description, price, discount, image_url, product_type } =
    product;

  const handleCartClick = () => {
    if (userData) {
      if (isInCart(id)) {
        removeFromCart(product);
      } else {
        addToCart(product);
      }
    } else {
      openPopup();
    }
  };

  return (
    <div className="relative group">
      {/* Основний блок */}
      <div className="flex flex-col h-full lg:h-[328px] justify-between bg-gradient-to-b from-neutral-900 from-40% to-orange-950 p-4 rounded-lg drop-shadow-md text-white group-hover:opacity-100 transition-opacity duration-200 relative z-10">
        {discount ? (
          <div className="absolute top-4 right-4 bg-orange-600 text-xs font-bold px-3 py-1 rounded-full z-20">
            {discount * 100}%
          </div>
        ) : (
          ""
        )}
        <div className="absolute top-5 left-4 text-xs rounded-lg z-20 drop-shadow-md">
          <p>{product_type.replace(/MINECRAFT/g, "").replace(/_/g, " ")}</p>
        </div>
        <div className="flex justify-center mb-4">
          <img
            src={image_url}
            alt={title[lang]}
            className="w-44 h-auto sm:w-full sm:h-auto lg:w-32 lg:h-32 object-cover z-0"
          />
        </div>
        <div className="flex flex-col justify-between h-full">
          <h3 className="text-left font-bold md:text-lg mb-2">{title[lang]}</h3>
          <div className="flex justify-between items-center">
            <div className="text-start flex flex-col">
              {discount ? (
                <div className="absolute top-4 right-4 bg-orange-600 text-xs font-bold px-3 py-1 rounded-full z-20">
                  {discount * 100}%
                </div>
              ) : (
                ""
              )}
              <span className="text-orange-500 font-bold">
                ${(price - price * discount).toFixed(2)}
              </span>
            </div>
            <button
              onClick={handleCartClick}
              className="self-end bg-orange-500 hover:bg-orange-600 transition-colors duration-200 text-white font-bold p-2 rounded-lg relative z-20"
            >
              {isInCart(id) ? (
                <MdShoppingCart className="w-6 h-6 fill-current" />
              ) : (
                <MdOutlineShoppingCart className="w-6 h-6" />
              )}
            </button>
          </div>
          <h3 className="text-left text-gray-300 text-xs mb-4 group-hover:line-clamp-none lg:line-clamp-2 mt-2">
            {description[lang]}
          </h3>
        </div>
      </div>

      {/* Блок із повним описом */}
      <div className="absolute hidden lg:flex flex-col justify-between top-0 left-0 w-full min-h-full bg-gradient-to-b from-neutral-900 from-40% to-orange-950 p-4 rounded-lg drop-shadow-md text-white opacity-0 group-hover:opacity-100 transition-opacity duration-0 z-30 pointer-events-none">
        {discount ? (
          <div className="absolute top-4 right-4 bg-orange-600 text-xs font-bold px-3 py-1 rounded-full z-20">
            {discount * 100}%
          </div>
        ) : (
          ""
        )}
        <div className="absolute top-5 left-4 text-xs rounded-lg z-40">
          <p>{product_type.replace(/MINECRAFT/g, "").replace(/_/g, " ")}</p>
        </div>
        <div className="flex justify-center mb-4">
          <img
            src={image_url}
            alt={title[lang]}
            className="w-44 h-auto sm:w-full sm:h-auto lg:w-32 lg:h-32 object-cover"
          />
        </div>
        <div className="flex flex-col justify-between flex-1">
          <h3 className="text-left font-bold md:text-lg mb-2">{title[lang]}</h3>

          <div className="flex justify-between items-center">
            <div className="text-start flex flex-col">
              {discount ? (
                <div className="absolute top-4 right-4 bg-orange-600 text-xs font-bold px-3 py-1 rounded-full z-20">
                  {discount * 100}%
                </div>
              ) : (
                ""
              )}
              <span className="text-orange-500 font-bold">
                ${(price - price * discount).toFixed(2)}
              </span>
            </div>
            <button
              onClick={handleCartClick}
              className="self-end bg-orange-500 hover:bg-orange-600 transition-colors duration-200 text-white font-bold p-2 rounded-lg relative z-20"
            >
              {isInCart(id) ? (
                <MdShoppingCart className="w-6 h-6 fill-current" />
              ) : (
                <MdOutlineShoppingCart className="w-6 h-6" />
              )}
            </button>
          </div>
          <h3 className="text-left text-gray-300 text-xs mb-4 mt-2">
            {description[lang]}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
