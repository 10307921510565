import "./i18n";
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Landing from "./components/landing/Landing";
import LeftNavbar from "./components/leftNavbar/LeftNavbar";
import Header from "./components/header/Header";
import Servers from "./components/servers/Servers";
import Cart from "./components/cart/Cart";
import Auth from "./components/auth/Auth";
import Registration from "./components/registration/Registration";
import Footer from "./components/footer/Footer";
import UserProfile from "./components/userProfile/UserProfile";
import Page404 from "./components/page404/Page404";
import MinecraftShop from "./components/minecraftShop/MinecraftShop";
import Bot from "./components/bot/Bot";
import Plugin from "./components/plugin/Plugin";
import AboutUs from "./components/aboutUs/AboutUs";
import { getUserData } from "./api/authService";
import AuthorizeCallback from "./components/authorizeCallback/AuthorizeCallback";
import { useCart } from "./context/CartContext";
import ResetPassword from "./components/resetPassword/ResetPassword";
import Paid from "./components/paid/Paid";
import { initGA, logPageView } from "./utils/analytics";
import PaidError from "./components/paid/PaidError";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    logPageView();
  }, [pathname]);

  return null;
}

function MainContent({
  isMobileMenuOpen,
  setIsMobileMenuOpen,
  userData,
  isLoading,
}) {
  const { pathname } = useLocation();

  useEffect(() => {
    if (isMobileMenuOpen) {
      setIsMobileMenuOpen(false);
    }
  }, [pathname]);

  return (
    <>
      <ScrollToTop />
      <div className="flex-1 px-4">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="servers" element={<Servers />} />
          <Route path="cart" element={<Cart />} />
          <Route path="auth" element={<Auth />} />
          <Route path="registration" element={<Registration />} />
          <Route
            path="profile"
            element={<UserProfile userData={userData} isLoading={isLoading} />}
          />
          <Route
            path="minecraft-shop"
            element={<MinecraftShop userData={userData} />}
          />
          <Route path="plugin" element={<Plugin userData={userData} />} />
          <Route path="bot" element={<Bot userData={userData} />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="/authorizeCallback" element={<AuthorizeCallback />} />
          <Route path="/authorize/resetPassword" element={<ResetPassword />} />
          <Route path="/payment/callback" element={<Paid />} />
          <Route path="/payment/failed" element={<PaidError />} />
          <Route path="*" element={<Page404 />} />
        </Routes>
      </div>
    </>
  );
}

function AppContent() {
  useEffect(() => {
    initGA();
  }, []);

  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const { pathname } = useLocation();
  const { clearCart } = useCart();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const data = await getUserData(clearCart);
        setUserData(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setUserData(null);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [pathname]);

  function useMediaQuery(query) {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      media.addEventListener("change", listener);
      return () => media.removeEventListener("change", listener);
    }, [query, matches]);

    return matches;
  }

  return (
    <div className="App text-white min-h-screen flex relative">
      {isMobileMenuOpen && (
        <div
          className="fixed inset-0 bg-neutral-950 opacity-50 z-20"
          onClick={() => setIsMobileMenuOpen(false)}
        ></div>
      )}
      {isMobile ? (
        <div
          className={`fixed top-0 left-0 h-full bg-neutral-900 z-30 transition-transform duration-300 w-4/5 sm:w-auto ${
            isMobileMenuOpen ? "translate-x-0" : "-translate-x-full"
          }`}
        >
          <LeftNavbar
            isCollapsed={isCollapsed}
            toggleCollapse={() => setIsCollapsed(!isCollapsed)}
            onNavigate={() => setIsMobileMenuOpen(false)}
            userData={userData}
          />
        </div>
      ) : (
        <div
          className={`hidden lg:block transition-all duration-300 h-dvh sticky top-0 overflow-y-auto ${
            isCollapsed ? "w-16" : "w-60"
          }`}
        >
          <LeftNavbar
            isCollapsed={isCollapsed}
            toggleCollapse={() => setIsCollapsed(!isCollapsed)}
            userData={userData}
          />
        </div>
      )}
      <div className="flex-1 bg-neutral-800 transition-all duration-300 min-h-screen flex flex-col justify-between">
        <div className="sticky top-0 z-20 lg:z-40 w-auto">
          <Header
            toggleMobileMenu={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            userData={userData}
          />
        </div>
        <MainContent
          isMobileMenuOpen={isMobileMenuOpen}
          setIsMobileMenuOpen={setIsMobileMenuOpen}
          userData={userData}
          isLoading={isLoading}
        />
        <Footer />
      </div>
    </div>
  );
}

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
